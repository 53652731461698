import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import moment from "moment-timezone";
import { Link, useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import Alert from "../../components/Alert";
import {
  Button,
  ChecklistButton,
  ButtonWrapper,
} from "../../components/Button";
import { FormField, textInputClass } from "../../components/Form";
import Layout from "../../components/Layout";
import { BaseLivlyApiResponse } from "../../types/Base";
import { ServiceTypeEnum } from "../../types/Building";
import { OnboardingStatus, OnboardingStatusEnum } from "../../types/User";
import { BASE_API_URL } from "../../utils/constants";
import { AmenityBooking } from "../bookings";
import {
  useGetStatusTypeDetails,
  StatusTypeDetails,
  usePostService,
} from "./landing";
import DatePicker from "react-datepicker";
import { Fragment, forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MoveMatcherLogo from "../../assets/MoveMatcherLogo.svg";
import MMBackground from "../../assets/MMBackground.svg";
import { Dialog, Transition } from "@headlessui/react";
import {
  trackEditMoveInScheduleDate,
  trackFinishMoveInSchedule,
} from "../../utils/analytics";
import { Spinner } from "../../components/Spinner";
import useLivlyUser from "../../context/UserProvider";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { isBefore, addDays } from "date-fns";
import { isNativeAppVersion, returnToNative } from "@/utils/nativeAppHelpers";

export interface OnboardingAmenitySpace {
  amenitySpaceId: number;
  imageUri: string;
  isDeleted: boolean;
  isDraft: boolean;
  name: string;
  rate: string;
  waiver: null;
}

type ScheduleMoveInMetaData = {
  isEmailEnabled: boolean;
  onboardingAmenitiesEnabled: boolean;
};

const getAmenitySpaces = async (propertyId: string) => {
  const { data } = await axios.get<
    BaseLivlyApiResponse<OnboardingAmenitySpace[]>
  >(`${BASE_API_URL}/livly/amenity/space/property/${propertyId}/onboarding`);

  return data.Data;
};

const getOnboardingAmenityBookings = async (leaseId: string) => {
  const { data } = await axios.get<BaseLivlyApiResponse<AmenityBooking[]>>(
    `${BASE_API_URL}/livly/amenity/bookings/me/onboarding/${leaseId}`
  );

  return data.Data;
};

const getOnboardingAmenityData = async (
  leaseId: string,
  propertyId: string
) => {
  const [amenitySpaces, amenityBookings] = await Promise.all([
    getAmenitySpaces(propertyId),
    getOnboardingAmenityBookings(leaseId),
  ]);

  return { amenitySpaces, amenityBookings };
};

const onboardingAmenitiesQuery = (leaseId: string, propertyId: string) => ({
  queryKey: ["schedule-move-in", "amenities", leaseId],
  queryFn: async () => getOnboardingAmenityData(leaseId, propertyId),
});

export default function ChecklistScheduleMoveInPage() {
  const [showMoveMatcher, setShowMoveMatcher] = useState(false);
  const { user } = useLivlyUser();
  const navigate = useNavigate();
  const params = useParams<{ leaseId: string }>();
  const scheduleMoveInMetaData =
    user.building.serviceTypes?.find(
      (service) => service.serviceType === ServiceTypeEnum.ScheduleMoveIn
    )?.metaData ?? "";
  const parsed = scheduleMoveInMetaData
    ? (JSON.parse(scheduleMoveInMetaData) as ScheduleMoveInMetaData)
    : null;
  const scheduleMoveInEmailEnabled = parsed?.isEmailEnabled ?? false;
  const onboardingAmenitiesEnabled =
    parsed?.onboardingAmenitiesEnabled ?? false;

  const details = useGetStatusTypeDetails(
    OnboardingStatusEnum.schduleMoveIn,
    user
  );

  const { updateOnboardingStatus, skipOnboardingStep, isLoading } =
    usePostService(user.userId, params.leaseId!, details.onboardingStatus);

  const onSubmit = async (data: OnboardingStatus) => {
    try {
      await updateOnboardingStatus(data);
      trackFinishMoveInSchedule(false);

      if (isNativeAppVersion()) {
        returnToNative();
        return;
      }

      navigate(`..`);
    } catch (e) {
      const error = e as { data?: { Message: string } };
      alert(error ?? "There was an error saving schedule move in");
    }
  };

  const onSkip = async () => {
    try {
      setShowMoveMatcher(true);
      trackFinishMoveInSchedule(true);
      await skipOnboardingStep();
    } catch (e) {
      const error = e as { data?: { Message: string } };
      alert(error ?? "There was an error skipping schedule move in");
    }
  };

  const onCloseMoveMatcher = () => {
    setShowMoveMatcher(false);

    if (isNativeAppVersion()) {
      returnToNative();
      return;
    }

    navigate(`..`);
  };

  return (
    <Layout
      title="Schedule Move-in"
      back={{
        label: "Move-in Checklist",
        to: `..`,
      }}
    >
      <div className="pb-24">
        <div className="flex justify-end">
          <Button color="default" onClick={onSkip} className="">
            Skip
          </Button>
        </div>
        {scheduleMoveInEmailEnabled ? (
          <ScheduleMoveIn
            onSubmit={onSubmit}
            details={details}
            isLoading={isLoading}
          />
        ) : null}
        {onboardingAmenitiesEnabled ? (
          <div
            className={classNames({
              "mt-10": scheduleMoveInEmailEnabled,
            })}
          >
            <OnboardingAmenities />
          </div>
        ) : null}
      </div>
      <MoveMatcher open={showMoveMatcher} onClose={onCloseMoveMatcher} />
    </Layout>
  );
}

const ScheduleMoveInSchema = z.object({
  time: z.string({ required_error: "Please select a date" }),
  date: z.any(),
});

type ScheduleMoveRequest = z.infer<typeof ScheduleMoveInSchema>;

function ScheduleMoveIn({
  details,
  onSubmit,
  isLoading,
}: {
  isLoading: boolean;
  details: StatusTypeDetails;
  onSubmit: (data: OnboardingStatus) => void;
}) {
  const { user } = useLivlyUser();
  const today = new Date();
  const moveInDate = new Date(user.leaseMoveInDate);

  const parsedMetaData = details.onboardingStatus.metaData
    ? (JSON.parse(details.onboardingStatus.metaData) as {
        date: string;
        time: string;
      })
    : null;

  const [date, setDate] = useState<Date | null>(
    parsedMetaData
      ? new Date(parsedMetaData.date)
      : isBefore(today, moveInDate)
      ? today
      : moveInDate
  );

  const handleSubmit = (data: ScheduleMoveRequest) => {
    const note = `${moment(data.date).format("MMM D, YYYY")}, ${data.time}`;

    const onboardingStatus: OnboardingStatus = {
      ...details.onboardingStatus,
      isCompleted: true,
      isSkipped: false,
      notes: note,
      metaData: JSON.stringify({ ...data }),
    };

    onSubmit(onboardingStatus);
  };

  let initialValues: ScheduleMoveRequest = {
    date: parsedMetaData
      ? moment(parsedMetaData.date)
      : isBefore(today, moveInDate)
      ? today
      : moveInDate,
    time: parsedMetaData?.time ?? "",
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref) => (
    <input
      className={textInputClass}
      onClick={onClick}
      //@ts-ignore
      ref={ref}
      value={value}
    />
  ));

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={toFormikValidationSchema(ScheduleMoveInSchema)}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, setValues }) => {
          return (
            <Form id="scheduleForm">
              <FontAwesomeIcon
                icon={details.icon}
                className="text-3xl text-red-300"
              />
              <h2 className="mt-2 text-xl font-medium">{details.title}</h2>
              <p className="text-sm">{details.description}</p>
              <div className="mt-10">
                <h5 className="">Select a physical move-in date</h5>
                <p className="mt-1 text-sm">
                  Your lease start date is{" "}
                  {moment(user.leaseStartDate).format("MM/DD/YYYY")}. Selection
                  below will not affect your lease start date.
                </p>
                <FormField htmlFor="date" label="" className="w-full">
                  <DatePicker
                    wrapperClassName="w-full sm:w-auto"
                    minDate={addDays(new Date(), 1)}
                    selected={date ?? null}
                    placement="bottom"
                    onChange={(date: Date) => {
                      setDate(date);
                      setValues({ ...values, date });
                      trackEditMoveInScheduleDate();
                    }}
                    customInput={<ExampleCustomInput />}
                  />
                  {/* <SingleDatePicker
                    displayFormat="MMMM D, YYYY"
                    date={date}
                    onDateChange={(e) => {
                      setDate(e);
                      setValues({ ...values, date: e });
                      trackEditMoveInScheduleDate();
                    }}
                    numberOfMonths={1}
                    id="date_input"
                    isOutsideRange={(day) => day.isBefore(moment())}
                    focused={focused}
                    onFocusChange={(e) => setFocused(e.focused)}
                    noBorder
                    disabled={details.onboardingStatus.isCompleted}
                    customInputIcon={
                      <FontAwesomeIcon icon={["fal", "calendar"]} />
                    }
                  /> */}
                  <Field
                    name="date"
                    type="hidden"
                    value={JSON.stringify(date)}
                  />
                </FormField>
              </div>
              <div className="mt-10">
                <h5 className="">Select a preferred move-in time</h5>
                <p className="mt-1 text-sm">
                  A property manager will reach out to confirm your move-in
                  time. Preferred times are not guaranteed.
                </p>
                <FormField htmlFor="time" label="">
                  <div className="gap-8 space-y-3 md:flex md:space-y-0">
                    {[
                      ["Morning", "Morning"],
                      ["Afternoon", "Afternoon"],
                      ["NoPreference", "No Preference"],
                    ].map(([value, label]) => (
                      <label className="flex items-center " key={value}>
                        <Field
                          disabled={details.onboardingStatus.isCompleted}
                          id="time"
                          name="time"
                          type="radio"
                          value={value}
                          className="hidden w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500 peer"
                        />
                        <span
                          className={classNames(
                            "w-full md:w-auto text-center px-4 py-2 border border-gray-200 rounded-lg transition-color duration-300 cursor-pointer",
                            {
                              "border-blue-700 bg-blue-500 text-white peer-disabled:bg-gray-300 peer-disabled:border-gray-500":
                                values.time === value,
                              "hover:bg-gray-100 peer-disabled:bg-gray-100":
                                values.time !== value,
                            }
                          )}
                        >
                          {label}
                        </span>
                      </label>
                    ))}
                  </div>
                </FormField>
              </div>
              <p className="mt-4 text-sm text-gray-500">
                The date and times selected above will not change your official
                lease start date. These selections are merely an indicator for
                your property management company to assist you with moving in
                and scheduling the loading dock or elevator (if applicable).
              </p>
              {details.onboardingStatus.isCompleted ? (
                <ChecklistButton to=".." label="Done" type="button" />
              ) : (
                <ButtonWrapper>
                  <Button
                    type="submit"
                    className="w-full md:w-auto"
                    color="secondary"
                    disabled={isLoading}
                  >
                    {isLoading && <Spinner />}
                    Looks good! Next
                  </Button>
                </ButtonWrapper>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

function OnboardingAmenities() {
  const { user } = useLivlyUser();
  const params = useParams<{ leaseId: string }>();

  const { data, isLoading, isError, refetch } = useQuery({
    ...onboardingAmenitiesQuery(params.leaseId!, user.propertyId.toString()),
  });

  const availableAmenities = data?.amenitySpaces.filter(
    (as) => !as.isDeleted && !as.isDraft
  );

  return (
    <>
      {isError ? (
        <Alert
          message="There was an issue loading amenities. Please try again soon."
          action={{ label: "Try again", onClick: refetch }}
        />
      ) : isLoading ? (
        <div>
          <p>Loading amenity spaces. Please wait...</p>
        </div>
      ) : (
        <div>
          <h2 className="text-xl font-medium">Available Amenities</h2>
          <ul className="grid mt-4 lg:grid-cols-4 lg:gap-4">
            {availableAmenities?.map((a) => {
              const anyAmenityBookingsForSpace = data?.amenityBookings.some(
                (b) => b.amenitySpace?.amenitySpaceId === a.amenitySpaceId
              );

              return (
                <li
                  key={a.amenitySpaceId}
                  className="flex flex-row mb-4 shadow-none text-inherit lg:shadow-md lg:flex-col lg:rounded-xl lg:border-gray-400 lg:hover:bg-gray-50"
                >
                  {a.imageUri ? (
                    <img
                      className="flex-shrink-0 object-contain w-24 lg:w-full lg:h-36 lg:object-cover lg:rounded-tl-xl lg:rounded-tr-xl"
                      src={a.imageUri}
                      alt={a.name}
                    />
                  ) : (
                    <div className="flex items-center justify-center flex-shrink-0 w-24 bg-gray-400 lg:w-full lg:h-36 lg:rounded-tl-xl lg:rounded-tr-xl">
                      <FontAwesomeIcon icon="building" size="3x" />
                    </div>
                  )}
                  <div className="flex-1 p-4 ml-3 border-b border-gray-400 lg:border-none lg:ml-0">
                    <p>{a.name}</p>
                    <p className="text-sm font-light">{a.rate}</p>
                  </div>
                  <div className="flex flex-col p-4 space-y-2">
                    {anyAmenityBookingsForSpace ? (
                      <Link
                        to={`/lease/${params.leaseId}/bookings`}
                        state={{
                          returnTo: `/lease/${params.leaseId}/checklist/schedule-move-in`,
                          label: "Schedule Move-in",
                        }}
                      >
                        <Button color="default" className="w-full">
                          View Bookings
                        </Button>
                      </Link>
                    ) : (
                      <div />
                    )}
                    <Link
                      to={`/lease/${params.leaseId}/amenities/${user.propertyId}/${a.amenitySpaceId}`}
                      state={{
                        returnTo: `/lease/${params.leaseId}/checklist/${user.userId}/schedule-move-in`,
                        label: "Schedule Move-in",
                      }}
                    >
                      <Button color="secondary" className="w-full">
                        Book
                      </Button>
                    </Link>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}

function MoveMatcher({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  if (!open) {
    return null;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center h-full min-h-full p-4 text-center md:items-center md:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#2a7de1] h-full md:rounded-lg md:max-w-md md:w-full md:h-auto z-50 p-5 md:m-auto">
                <div className="flex flex-col justify-between h-full text-left">
                  <div>
                    <div className="flex justify-end">
                      <button
                        onClick={onClose}
                        className="px-2 py-1 text-white rounded-lg"
                      >
                        Skip
                      </button>
                    </div>
                    <img src={MoveMatcherLogo} alt="MoveIn Matcher" />
                    <h4 className="mt-10 text-2xl text-white">
                      {t("move-matcher-title")}
                    </h4>
                    <p className="mt-4 text-white">{t("move-matcher-desc")}</p>
                    <a
                      className="bg-[#fef7a7] text-[#1a5397] px-4 py-2 hover:bg-white transition duration-200 rounded-md mt-10 inline-block"
                      href="https://urldefense.proofpoint.com/v2/url?u=https-3A__www.movematcher.com_livly-2Diframe_-3Futm-5Fsource-3Dlivly-26utm-5Fmedium-3Daffiliate&d=DwMF-g&c=euGZstcaTDllvimEN8b7jXrwqOf-v5A_CdpgnVfiiMM&r=levhIfF_dUZkQo2alAtIKhzzOq9ieq49kooz4DN8P-g&m=F76gxIdRRGRgzM8-raYDMO1LEEw394WK9ZGb0DXG3AY&s=CQIwMp8Nw9Fk7lBn3mB8L8jq3eKMvFjFQzmLvcJiEjg&e="
                      target="_blank"
                    >
                      {t("move-matcher-quote")}
                    </a>
                  </div>
                  <img src={MMBackground} alt="" className="mt-20 " />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
