import { useTranslation } from "react-i18next";
import numeral from "numeral";
import Layout from "../../components/Layout";
import { useNavigate, useOutletContext } from "react-router-dom";
import { RentContext } from "../../context/RentProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "../../components/Dialog";
import { useEffect, useState } from "react";
import classNames from "classnames";
import useDebounce from "../../hooks/useDebounce";
import { ServiceTypeEnum } from "../../types/Building";
import useServiceFee from "../../hooks/useGetServiceFee";
import Alert from "../../components/Alert";
import { RentBalanceTypeEnum } from "../../types/Rent";
import { Button } from "../../components/Button";
import axios from "axios";
import { BASE_API_URL } from "../../utils/constants";
import { CartItem, FeeData } from "../../types/Cart";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import isCurrency from "../../utils/isCurrency";
import { SelectPaymentAccount } from "../../components/SelectPaymentAccount";
import {
  trackMakeRentPaymentSuccess,
  trackStartPayRent,
} from "../../utils/analytics";
import { Spinner } from "../../components/Spinner";
import CurrencyInput from "react-currency-input-field";
import useLivlyUser from "../../context/UserProvider";

const currencyFormat = "$0,0[.]00";

type CheckoutItem = Pick<
  CartItem,
  | "type"
  | "paymentMethodId"
  | "cost"
  | "paymentAmount"
  | "paymentInterestAmount"
  | "feeData"
>;

const postMakePayment = async (
  leaseId: number,
  userId: number,
  checkoutItems: CheckoutItem[]
) => {
  const response = await axios.post(
    `${BASE_API_URL}/livly/checkout/v2/${userId}/makepayment/${leaseId}`,
    checkoutItems
  );

  return response.data;
};

function LivlyRentPayPage() {
  const queryClient = useQueryClient();
  const { user } = useLivlyUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate, isLoading: isPaymentProcessing } = useMutation(
    (checkoutItems: CheckoutItem[]) =>
      postMakePayment(user.propertyUnitLeaseId, user.userId, checkoutItems)
  );
  const {
    rentSettings,
    selectedPaymentAccount,
    setSelectedPaymentAccountId,
    paymentType,
    setPaymentType,
    paymentAmount,
    setPaymentAmount,
    paymentAccounts,
  } = useOutletContext() as RentContext;
  const balance = user.ledgerBalance ?? 0;

  const [paymentErrors, setPaymentErrors] = useState<string[]>([]);
  const [isPaymentDefinitionsOpen, setIsPaymentDefinitionsOpen] =
    useState(false);
  const [isConfirmPaymentOpen, setIsConfirmPaymentOpen] = useState(false);
  const isFullBalanceOptionDisabled = balance <= 0;
  const isPaymentAmountInvalid =
    paymentAmount !== "" && !isCurrency(paymentAmount);

  const paymentAmountCurrency = numeral(paymentAmount).value();
  const isDwollaLimitError = false;
  const isMaxPaymentAllowedError =
    paymentAmountCurrency !== null &&
    paymentAmountCurrency > (rentSettings?.maxPaymentAmountAllowed ?? 0);

  // This is passed down logic for the fixed payment option change but needed in this parent as well for presentation.
  let debouncedPaymentAmount = useDebounce(paymentAmount, 500);
  const { feeData: fixedFeeData, loading: fixedFeeLoading } = useServiceFee(
    user.propertyId,
    ServiceTypeEnum.Rent,
    selectedPaymentAccount,
    Number(debouncedPaymentAmount.replace(/[^\d.-]/g, ""))
  );

  // Getting fee for Full Payment
  const {
    feeData: fullFeeData,
    loading: fullFeeLoading,
    error: fullFeeError,
  } = useServiceFee(
    user.propertyId,
    ServiceTypeEnum.Rent,
    selectedPaymentAccount,
    balance <= 0 ? 0 : balance ?? 0
  );

  const onSubmit = () => {
    if (!selectedPaymentAccount) {
      return;
    }

    let feeData: FeeData | null = null;

    if (paymentType === "fixed" && fixedFeeData) {
      feeData = fixedFeeData;
    } else if (paymentType === "full" && fullFeeData) {
      feeData = fullFeeData;
    }

    if (!feeData) {
      return;
    }

    let checkoutItem: CheckoutItem = {
      type: 1,
      paymentMethodId: selectedPaymentAccount?.id,
      cost: balance,
      paymentAmount: Number(paymentAmount),
      paymentInterestAmount: feeData.calculatedFeeAmount,
      feeData: feeData,
    };

    setPaymentErrors([]);

    mutate([checkoutItem], {
      onSuccess: (data) => {
        trackMakeRentPaymentSuccess(
          paymentType === "full",
          Number(paymentAmount),
          selectedPaymentAccount?.paymentType === "Credit Card",
          feeData!.calculatedFeeAmount,
          true
        );
        const amount =
          paymentType === "fixed" && fixedFeeData !== null
            ? numeral(fixedFeeData.totalAmount).format("$0,0[.]00")
            : fullFeeData
            ? numeral(fullFeeData.totalAmount).format("$0,0[.]00")
            : null;
        const transactionId = data.Data[0].livlyTransactionId;
        const params = {
          transactionId: transactionId ? transactionId : 0,
          amount,
          paymentDescription: selectedPaymentAccount?.description || "",
        };
        queryClient.invalidateQueries({ queryKey: ["user"] });
        navigate(
          `/lease/${user.propertyUnitLeaseId}/rent/${user.userId}/livly/confirmation`,
          { state: params, replace: true }
        );
      },
      onError: (error) => {
        const mutationError = error as {
          data?: { Errors: Record<number, string>; Message: string };
        };
        const errors: string[] =
          Object.values(mutationError.data?.Errors ?? {}) ?? [];
        setPaymentErrors(errors);

        trackMakeRentPaymentSuccess(
          paymentType === "full",
          Number(paymentAmount),
          selectedPaymentAccount?.paymentType === "Credit Card",
          feeData!.calculatedFeeAmount,
          false
        );
      },
    });
  };

  useEffect(() => {
    trackStartPayRent();
  }, []);

  const isSubmitDisabled =
    isPaymentProcessing ||
    fullFeeLoading ||
    fixedFeeLoading ||
    isDwollaLimitError ||
    paymentType == null ||
    (rentSettings?.rentBalanceType === RentBalanceTypeEnum.BalanceBased
      ? (paymentType === "fixed" && isPaymentAmountInvalid) ||
        isPaymentProcessing ||
        (paymentType === "fixed" &&
          paymentAmountCurrency !== null &&
          paymentAmountCurrency > balance) ||
        (paymentType === "fixed" && !paymentAmountCurrency)
      : // add free form conditions here
        (paymentType === "fixed" && paymentAmount === "") ||
        (paymentType === "full" && balance <= 0) ||
        (paymentType === "fixed" &&
          paymentAmount !== "" &&
          paymentAmountCurrency !== null &&
          paymentAmountCurrency <= 0) ||
        isMaxPaymentAllowedError);

  return (
    <Layout title="Pay Rent" back={{ to: -1, label: "Rent" }}>
      {isPaymentProcessing ? (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-white bg-opacity-75">
          <Spinner color="livly" size="xl" />
        </div>
      ) : null}
      <div className="pb-40">
        <div className="pb-4 border-b border-gray-200">
          <p className="text-sm text-gray-500">
            {t("rent-dashboard.current-balance")}
          </p>
          <p className="text-2xl font-light">
            {numeral(user.ledgerBalance ?? 0).format("$0,0[.]00")}
          </p>
        </div>
        <div className="pt-4">
          <SelectPaymentAccount
            selectedPaymentAccountId={selectedPaymentAccount!.id}
            paymentAccounts={paymentAccounts.filter((pa) => pa.isActive)}
            onSelect={setSelectedPaymentAccountId}
          />
        </div>
        <div className="pt-4">
          <div className="flex justify-between">
            <p className="text-sm text-gray-500 lowercase">
              {t("make-payment.select-payment-amount")}
            </p>
            <button onClick={() => setIsPaymentDefinitionsOpen(true)}>
              <FontAwesomeIcon
                icon={["fal", "info-circle"]}
                className="text-gray-500"
              />
            </button>
          </div>
        </div>
        <label
          className={classNames(
            "py-4 border-b border-gray-200 flex justify-between items-center gap-4",
            { "text-gray-400": isFullBalanceOptionDisabled }
          )}
          onClick={() => {
            if (isFullBalanceOptionDisabled) {
              return;
            }
            setPaymentType("full");
          }}
        >
          <div>
            <p className="text-3xl">
              {numeral(
                (user.ledgerBalance ?? 0) > 0 ? user.ledgerBalance : 0
              ).format("$0,0[.]00")}
            </p>
            <p>
              {fullFeeLoading
                ? "Calculating fee"
                : fullFeeData
                ? `${t("general.processing-fee")}: ${numeral(
                    fullFeeData.calculatedFeeAmount
                  ).format(currencyFormat)}`
                : null}{" "}
            </p>
            <p className="mt-1 text-gray-500">{t("make-payment.full")}</p>
          </div>
          {paymentType === "full" && (
            <FontAwesomeIcon icon="check" className="text-red-400" />
          )}
        </label>
        <label
          className="flex items-center justify-between gap-4 py-4 border-b border-gray-200"
          onClick={() => setPaymentType("fixed")}
          htmlFor="rent-payment-amount"
        >
          <div className="flex-1">
            <div className="relative mt-1">
              <div className="absolute inset-y-0 left-0 flex items-center pl-0 pointer-events-none">
                <span
                  className={classNames("text-3xl", {
                    "text-red-400": isPaymentAmountInvalid,
                  })}
                >
                  $
                </span>
              </div>
              <CurrencyInput
                name="rent-payment-amount"
                id="rent-payment-amount"
                className={classNames(
                  "peer border-none w-full outline-none focus:ring-0 pl-6 pr-6 text-3xl bg-white",
                  { "text-red-400": isPaymentAmountInvalid }
                )}
                value={paymentAmount ?? ""}
                decimalsLimit={2}
                allowNegativeValue={false}
                onValueChange={(value) => {
                  setPaymentAmount(value ?? "");
                }}
              />
              {paymentAmount !== "" && (
                <div className="absolute inset-y-0 right-0 items-center pl-0">
                  <FontAwesomeIcon
                    icon="times-circle"
                    className="text-sm text-gray-400"
                    onClick={() => setPaymentAmount("")}
                  />
                </div>
              )}
            </div>
            <p className="mt-1 text-xs text-gray-500">
              {fixedFeeLoading
                ? "Calculating fee"
                : fixedFeeData
                ? `${t("general.processing-fee")}: ${numeral(
                    fixedFeeData.calculatedFeeAmount
                  ).format(currencyFormat)}`
                : null}{" "}
            </p>
            <p className="mt-1 text-gray-500">{t("make-payment.fixed")}</p>
          </div>
          {paymentType === "fixed" && (
            <FontAwesomeIcon icon="check" className="text-red-400" />
          )}
        </label>
        {isDwollaLimitError && (
          <div className="mt-2">
            <Alert
              variant="danger"
              message="ACH transactions are limited to $10,000 per week"
            />
          </div>
        )}
        {isMaxPaymentAllowedError && (
          <div className="mt-2">
            <Alert
              variant="danger"
              message="You have reached your maximum payment limit."
            />
          </div>
        )}
        <div className="py-4 text-xs text-gray-500">
          <p>
            <span className="font-medium">
              {t("make-payment.notice.full-label")}
            </span>{" "}
            - {t("make-payment.notice.full-description")}
          </p>
          <p className="mt-4">
            <span className="font-medium">
              {t("make-payment.notice.fixed-label")}
            </span>{" "}
            - {t("make-payment.notice.fixed-description")}
          </p>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0 flex flex-col items-stretch m-4 bg-white border border-gray-100 rounded-lg shadow-lg md:flex-row md:justify-between md:items-baseline md:left-64 drop-shadow-lg">
        <div className="flex flex-col items-center justify-between w-full p-4 border-t border-gray-200 md:flex-row md:border-none">
          <div className="flex flex-col items-center justify-center mr-4 md:items-baseline">
            <p className="text-2xl">
              {paymentType === "fixed" && fixedFeeData
                ? numeral(fixedFeeData.totalAmount).format("$0,0[.]00")
                : fullFeeData
                ? numeral(fullFeeData.totalAmount).format("$0,0[.]00")
                : null}
            </p>
            <p className="text-xs font-light md:mr-2">payment total</p>
          </div>
          <Button
            color="secondary"
            onClick={() => setIsConfirmPaymentOpen(true)}
            disabled={isSubmitDisabled}
            className="flex items-center w-full gap-2 mt-4 md:w-auto md:mt-0"
          >
            {isPaymentProcessing && <Spinner />}
            Submit Rent Payment
          </Button>
        </div>
      </div>
      <PaymentDefinationDialog
        open={isPaymentDefinitionsOpen}
        onClose={setIsPaymentDefinitionsOpen}
        rentTotal={
          paymentType === "fixed" && paymentAmount
            ? numeral(paymentAmount).format(currencyFormat)
            : numeral(user.ledgerBalance ?? 0).format(currencyFormat)
        }
        feeTotal={
          paymentType === "fixed" && fixedFeeData
            ? `${numeral(fixedFeeData.calculatedFeeAmount).format(
                currencyFormat
              )}`
            : fullFeeData
            ? `${numeral(fullFeeData.calculatedFeeAmount).format(
                currencyFormat
              )}`
            : ""
        }
      />
      <ConfirmPaymentDialog
        isLoading={isPaymentProcessing}
        open={isConfirmPaymentOpen}
        onClose={() => {
          if (isPaymentProcessing) {
            return;
          }

          setIsConfirmPaymentOpen(false);
          setPaymentErrors([]);
        }}
        onConfirm={onSubmit}
        errors={paymentErrors}
        totalAmount={
          paymentType === "fixed" && fixedFeeData !== null
            ? numeral(fixedFeeData.totalAmount).format("$0,0[.]00")
            : fullFeeData
            ? numeral(fullFeeData.totalAmount).format("$0,0[.]00")
            : null
        }
      />
    </Layout>
  );
}

export { LivlyRentPayPage };

function PaymentDefinationDialog({
  open,
  onClose,
  rentTotal,
  feeTotal,
}: {
  open: boolean;
  onClose: Function;
  rentTotal: string;
  feeTotal: string;
}) {
  return (
    <Modal
      title="Payment Definitions"
      open={open}
      onClose={() => onClose(false)}
    >
      <div className="mt-4 text-sm">
        <p className="font-medium">Full statement balance</p>
        <p className="mt-2">
          This is the sum of your previous balance, payments, other credits,
          balances, and fees. It doesn't reflect any payments or new
          transactions since your statement date.
        </p>
        <p className="mt-4 font-medium">Fixed amount</p>
        <p className="mt-2">
          This is the amount you choose to pay each month. Note: If it's ever
          less than the minimum due, you'll have to make an additional payment.
        </p>
        <p className="mt-4 font-medium">Processing fee</p>
        <div className="flex items-baseline mt-4 font-medium">
          <p>Rent Total</p>
          <span className="flex-1 mx-2 border-b border-gray-200"></span>
          <p>{rentTotal}</p>
        </div>
        <div className="flex items-baseline mt-4 font-medium">
          <p>Processing fee</p>
          <span className="flex-1 mx-2 border-b border-gray-200"></span>
          <p>{feeTotal}</p>
        </div>
      </div>
    </Modal>
  );
}

function ConfirmPaymentDialog({
  open,
  onClose,
  totalAmount,
  isLoading,
  onConfirm,
  errors,
}: {
  open: boolean;
  onClose: Function;
  totalAmount: string | null;
  isLoading: boolean;
  onConfirm: () => void;
  errors: string[];
}) {
  return (
    <Modal title="Pay Rent" open={open} onClose={() => onClose(false)}>
      <div>
        {errors.length > 0 && (
          <div className="p-4 rounded-md bg-red-50">
            <div className="flex">
              <div className="flex-shrink-0">
                <FontAwesomeIcon
                  icon="exclamation-triangle"
                  className="w-5 h-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  We are sorry
                </h3>
                <div className="mt-2 text-sm text-red-700">
                  <ul className="space-y-2">
                    {errors.map((err) => (
                      <li key={err}>{err}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        <p className="py-4">
          Are you sure you want to submit a payment of{" "}
          <strong>{totalAmount}</strong>?
        </p>
        <div className="flex gap-3 mt-6">
          <Button
            className="flex-1"
            color="default"
            onClick={() => onClose(true)}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            className="flex-1"
            color="secondary"
            onClick={onConfirm}
            disabled={isLoading}
          >
            {isLoading && <Spinner color="white" size="md" />}
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
}
