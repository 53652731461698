import { Button } from "@/components/Button";
import { FormField, TextArea, textInputClass } from "@/components/Form";
import Layout from "@/components/Layout";
import useLivlyAttendee from "@/context/EventProvider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Field, Form, Formik, useFormikContext } from "formik";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { Attendee, updateRSVPEventStatus } from "./event";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "@/components/Spinner";
import toast from "react-hot-toast";
import { trackUpdateResponse } from "@/utils/analytics";
import { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toLocalTime from "@/utils/toLocalTime";
import { Dialog, Transition } from "@headlessui/react";

const camelCaseToWords = (str: string) =>
  str.replace(/([a-z])([A-Z])/g, "$1 $2");

const FormObserver: React.FC = () => {
  const { values } = useFormikContext();
  const { attendee, updateAttendee } = useLivlyAttendee();
  useEffect(() => {
    updateAttendee({
      ...attendee,
      ...(typeof values === "object" ? values : {}),
      attendingCount: Number((values as any).attendingCount) + 1
    });
  }, [values]);

  return null;
};

const MAX_NOTES_LENGTH = 200;

const EventRegister = () => {
  const params = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { attendee, resetAttendee } = useLivlyAttendee();
  const [isOpen, setIsOpen] = useState(false);

  const { mutateAsync, isLoading: isSaving } = useMutation((data: Attendee) =>
    updateRSVPEventStatus(Number(params.propertyId), params.eventId!, data)
  );

  const {
    rsvpFields,
    isRsvpNoteRequired,
    isGuestsInfoRequired,
    allowedGuestCount,
    attendingCount = 0,
    rsvpNote,
    guests,
    remainingSpots,
    title,
    rsvpDeadline,
    startDate,
    endDate,
    timezone,
    isAttending,
    totalSpotLeft
  } = attendee;

  useEffect(() => {
    if (!isRsvpNoteRequired && !isGuestsInfoRequired) {
      navigate(-1);
    }
  }, [isRsvpNoteRequired, isGuestsInfoRequired]);

  const maxGuests =
    remainingSpots && remainingSpots !== "Unlimited"
      ? Math.min(Number(allowedGuestCount) || 0, remainingSpots)
      : Number(allowedGuestCount) || 0;

  const AttendeeSchema = z.object({
    rsvpNote: isRsvpNoteRequired
      ? z.string().min(1, "RSVP note is required").max(MAX_NOTES_LENGTH)
      : z.string().optional(),
    attendingCount: z.number().optional(),
    guests: z
      .array(
        z.record(
          z.string(),
          z.union([z.string().trim().min(1, "Required"), z.number()])
        )
      )
      .min(0)
  });

  type AddAttendeeRequest = z.infer<typeof AttendeeSchema>;

  const initialValues: AddAttendeeRequest = {
    rsvpNote: rsvpNote || "",
    attendingCount: Math.max(0, attendingCount - 1),
    guests: guests || []
  };

  const onSubmit = async (values: AddAttendeeRequest) => {
    try {
      await mutateAsync({
        rsvpNote: values.rsvpNote || "",
        isAttending: true,
        attendingCount: (values.attendingCount || 0) + 1,
        guests: values.guests || []
      });
      trackUpdateResponse(params.eventId!);
      queryClient.invalidateQueries(["events"]);
      queryClient.invalidateQueries(["bookings"]);
      navigate(
        `/lease/${params.leaseId}/events/property/${params.propertyId}/${params.eventId}`,
        {
          state: { success: true }
        }
      );
    } catch (e) {
      toast.error(
        (e as { data?: { Message?: string } }).data?.Message ??
          "An error occurred"
      );
    }
  };

  const onCancel = async () => {
    //@ts-ignore
    const request: Attendee = {
      ...attendee,
      isAttending: false,
      attendingCount: 0
    };
    try {
      await mutateAsync(request);
      trackUpdateResponse(params.eventId!);
      queryClient.invalidateQueries(["events"]);
      queryClient.invalidateQueries(["bookings"]);
      resetAttendee();
      navigate(
        `/lease/${params.leaseId}/events/property/${params.propertyId}/${params.eventId}`,
        {
          state: { notAttending: true }
        }
      );
    } catch (e) {
      const error = e as { data?: { Message?: string } };
      toast.error(error?.data?.Message ?? "An error occurred");
    }
  };

  return (
    <Layout title="Register" back={{ to: -1, label: "Event Details" }}>
      <div className="max-w-lg mx-auto">
        <h1 className="my-2 text-xl font-semibold">{title}</h1>
        <div className="p-4 shadow  rounded">
          <div>
            <div className="flex gap-3 mb-3">
              <div className="flex-shrink-0 w-4 mt-1">
                <FontAwesomeIcon icon="clock" />
              </div>

              {startDate && endDate && (
                <time className="m-0 text-gray-600">
                  {toLocalTime(startDate, timezone).format(
                    "dddd, MMMM D, YYYY"
                  )}{" "}
                  at {toLocalTime(startDate, timezone).format("h:mm a")} to{" "}
                  {toLocalTime(endDate, timezone).format("dddd, MMMM D, YYYY")}{" "}
                  at {toLocalTime(endDate, timezone).format("h:mm a")}
                </time>
              )}
            </div>

            {rsvpDeadline && (
              <div className="mt-2 flex items-center gap-3 mb-3">
                <div className="flex-shrink-0 w-4 ">
                  <FontAwesomeIcon className="" icon="hourglass-clock" />
                </div>
                <div>
                  <p className="text-gray-600">
                    To attend this event, RSVP by{" "}
                    {toLocalTime(rsvpDeadline, timezone).format(
                      "MMM D, h:mm A"
                    )}
                  </p>
                </div>
              </div>
            )}
            {(totalSpotLeft !== null || totalSpotLeft !== undefined) && (
              <div className="mt-2 flex items-center gap-3 mb-3">
                <div className="flex-shrink-0 w-4">
                  <FontAwesomeIcon className="" icon="users" />
                </div>
                <div>
                  <p className="text-gray-600">
                    {" "}
                    {totalSpotLeft === "Unlimited"
                      ? `${totalSpotLeft} Spots`
                      : `${totalSpotLeft} spot(s) remaining`}{" "}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={toFormikValidationSchema(AttendeeSchema)}
          onSubmit={onSubmit}
        >
          {({ errors, touched, values, handleChange, setFieldValue }) => {
            return (
              <Form>
                {isGuestsInfoRequired && (
                  <FormField htmlFor="attendingCount" label="Number of guests">
                    <select
                      id="attendingCount"
                      name="attendingCount"
                      value={values.attendingCount}
                      onChange={(e) => {
                        const count = Number(e.target.value);

                        // Adjust the guests array
                        const updatedGuests = [...values.guests]; // Keep the existing data
                        if (count > updatedGuests.length) {
                          // Add empty guest objects for new guests
                          const newGuests = Array.from(
                            { length: count - updatedGuests.length },
                            () =>
                              Object.fromEntries(
                                (rsvpFields ?? []).map((key) => [key, ""])
                              )
                          );
                          updatedGuests.push(...newGuests);
                        } else if (count < updatedGuests.length) {
                          // Trim extra guest objects
                          updatedGuests.length = count;
                        }

                        // Update form values
                        setFieldValue("attendingCount", count);
                        setFieldValue("guests", updatedGuests);
                      }}
                      className="w-full"
                    >
                      {Array.from({ length: maxGuests || 1 }, (_, i) => (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      ))}
                    </select>
                  </FormField>
                )}

                {values.guests.map((guest, index) => (
                  <div key={index} className="mt-4">
                    <div className="flex justify-between items-center">
                      <h3 className="text-lg font-medium">
                        Guest {index + 1} Info
                      </h3>
                      <button
                        type="button"
                        onClick={() => {
                          const updatedGuests = values.guests.filter(
                            (_, i) => i !== index
                          );
                          setFieldValue("guests", updatedGuests);
                          setFieldValue("attendingCount", updatedGuests.length);
                        }}
                        className="text-red-400"
                      >
                        Remove
                      </button>
                    </div>
                    {Object.keys(guest)?.map((field) => (
                      <FormField
                        key={field}
                        htmlFor={`guest-${index}-${field}`}
                        label={camelCaseToWords(field)}
                        showErrorIcon={
                          values?.guests?.length > 0 &&
                          touched?.guests?.[index]?.[field] &&
                          //@ts-ignore
                          errors?.guests?.[index]?.[field]
                        }
                      >
                        <Field
                          id={`guest-${index}-${field}`}
                          name={`guests.${index}.${field}`}
                          className={textInputClass}
                        />
                      </FormField>
                    ))}
                  </div>
                ))}

                {isRsvpNoteRequired && (
                  <FormField
                    htmlFor="rsvpNote"
                    label="RSVP Notes"
                    showErrorIcon={errors.rsvpNote && touched.rsvpNote}
                  >
                    <TextArea
                      id="rsvpNote"
                      name="rsvpNote"
                      className="mt-2"
                      placeholder="List any allergies, sensitivities, or other relevant information"
                      onChange={handleChange}
                      maxLength={MAX_NOTES_LENGTH}
                    />
                    <div className="mt-2 text-sm text-gray-500 text-right">
                      {MAX_NOTES_LENGTH - (values.rsvpNote?.length ?? 0)}{" "}
                      characters remaining
                    </div>
                  </FormField>
                )}

                <div className="flex justify-end mt-4 gap-4 ">
                  {isAttending && (
                    <Button
                      type="button"
                      className="w-full md:w-auto"
                      color="danger"
                      onClick={() => setIsOpen(true)}
                      disabled={isSaving}
                    >
                      Cancel registration
                    </Button>
                  )}
                  <Button
                    size="small"
                    color="primary"
                    type="submit"
                    className="w-full md:w-auto"
                    disabled={
                      isSaving ||
                      (attendee.isRsvpNoteRequired &&
                        values.rsvpNote !== undefined &&
                        values?.rsvpNote.length === 0) ||
                      (isGuestsInfoRequired &&
                        Number(values?.attendingCount) > 0 &&
                        (values.guests?.length ?? 0) === 0)
                    }
                  >
                    {isSaving && <Spinner />}{" "}
                    {!isAttending ? "Register" : "Update Status"}
                  </Button>
                </div>
                <FormObserver />
              </Form>
            );
          }}
        </Formik>
        <CancelRegistration
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onCancel={() => onCancel()}
          isSaving={isSaving}
        />
      </div>
    </Layout>
  );
};

const CancelRegistration = ({
  isOpen,
  onClose,
  onCancel,
  isSaving
}: {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  isSaving: boolean;
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={onClose}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50" />

        <div className="fixed inset-0 z-30 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-md p-6 bg-white rounded-lg">
            <Dialog.Title className="text-lg font-medium text-gray-900">
              Cancel registration
            </Dialog.Title>
            <div className="mt-4">
              <p>
                Are you sure you want to cancel your registration for this
                event?
              </p>
            </div>
            <div className="mt-6 flex justify-end gap-3">
              <button
                className="px-4 py-2 text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300"
                onClick={onClose}
                disabled={isSaving}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 text-white bg-red-500 rounded-lg hover:bg-red-700 w-auto flex items-center"
                onClick={onCancel}
                disabled={isSaving}
              >
                {isSaving && <Spinner />}
                Yes, Cancel
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EventRegister;
