import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import {
  useParams,
  useNavigate,
  useOutletContext,
  useLocation,
} from "react-router-dom";
import GuestForm from "../../components/GuestForm";
import Layout from "../../components/Layout";
import { BASE_API_URL } from "../../utils/constants";
import { GuestResponse } from "./my-guests";
import useLivlyGuest, {
  DelegateServiceTypes,
  getFriendlyDayOfWeek,
  GuestRequest,
  useGetLocksScheduleSettings,
} from "@/context/GuestProvider";
import useLivlyUser from "@/context/UserProvider";
import { BaseLivlyApiResponse } from "@/types/Base";
import Alert from "@/components/Alert";
import { Spinner } from "@/components/Spinner";
import { useEffect, useMemo } from "react";

function useUpsertGuest(leaseId: string, propertyId: number) {
  const postGuest = async (guest: GuestRequest) => {
    return await axios.post(
      `${BASE_API_URL}/resident/guests/property/${propertyId}/lease/${leaseId}`,
      guest
    );
  };

  return useMutation(postGuest);
}

async function getGuest(leaseId: string, propertyId: number, guestId: string) {
  const result = await axios.get<BaseLivlyApiResponse<GuestResponse>>(
    `${BASE_API_URL}/resident/guests/property/${propertyId}/lease/${leaseId}/guest/${guestId}`
  );

  return result.data.Data;
}

function useGetGuest(leaseId: string, propertyId: number, guestId: string) {
  return useQuery({
    queryKey: ["guest", leaseId, propertyId, guestId],
    queryFn: () => getGuest(leaseId, propertyId, guestId),
  });
}

function useGetDeligateServices(propertyId: number, leaseId: string) {
  const queryClient = useQueryClient();

  const services: DelegateServiceTypes[] =
    queryClient.getQueryData(["delegate-services", propertyId, leaseId]) ?? [];
  return services;
}

export default function EditGuestPage() {
  const { isGuestServiceDisabled } = useOutletContext<{
    isGuestServiceDisabled: boolean;
  }>();
  const { redirect = false } =
    (useLocation().state as {
      redirect: false;
    }) || {};
  const scheduleFromSettings = useGetLocksScheduleSettings();
  const { user } = useLivlyUser();
  const { updateGuest, resetGuest } = useLivlyGuest();
  const queryClient = useQueryClient();
  const { id, leaseId } = useParams<{ id: string; leaseId: string }>();
  const navigate = useNavigate();
  const { mutate, isLoading, error } = useUpsertGuest(
    leaseId!,
    user.propertyId
  );

  const { data, isError } = useGetGuest(leaseId!, user.propertyId, id!);
  const services = useGetDeligateServices(user.propertyId, leaseId!);

  const onSubmit = (newGuest: GuestRequest) => {
    mutate(newGuest, {
      onSuccess: () => {
        navigate(`/lease/${user.propertyUnitLeaseId}/guests`);
        queryClient.invalidateQueries(["lease-guests"]);
        resetGuest();
      },
    });
  };

  const days = useMemo(
    () =>
      scheduleFromSettings.map(({ day, time, length, disabled }) => ({
        day: getFriendlyDayOfWeek(day),
        startTime: time,
        endTime: time + length,
        disabled,
      })),
    [scheduleFromSettings]
  );

  useEffect(() => {
    if (data && !redirect) {
      updateGuest({
        guestId: data.guestId,
        firstName: data.firstName,
        lastName: data.lastName,
        companyName: data.companyName,
        email: data.email,
        phone: data.phone,
        staffNotes: data.note ?? "",
        avatarUri: data.avatarUri,
        appPermission: data.appPermission,
        waiverAccepted: true,
        guestType: data.type.id.toString(),
        serviceTypes: services?.map((service) => ({
          ...service,
          enabled: data.serviceTypes?.find(
            (s) => s.serviceType === service.serviceType
          )?.enabled
            ? true
            : false,
        })),
        accessControl: data.accessControl
          ? {
              ...data.accessControl,
              schedule: {
                ...data.accessControl?.schedule,
                days:
                  data.accessControl?.schedule?.days.map((day) => ({
                    day: day.day,
                    startTime: day.startTime,
                    endTime: day.endTime,
                    disabled: day.disabled,
                  })) ?? [],
              },
            }
          : {
              allowUnitDoors: false,
              schedule: { days },
            },
      });
    }
  }, [data]);

  if (isError) {
    return (
      <div>
        <Alert
          message="An error occurred while fetching the guest"
          variant="danger"
        />
      </div>
    );
  }

  if (data) {
    return (
      <Layout back={{ to: "..", label: "My Guests" }} title="Edit Guest">
        <div className="max-w-3xl mx-auto">
          {isGuestServiceDisabled ? (
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Oooops
              </h3>
              <div className="mt-4">
                <p>
                  You do not have access to this feature. Please speak to your
                  property staff with any questions.
                </p>
              </div>
            </div>
          ) : (
            <>
              {services && (
                <GuestForm
                  error={error}
                  initialGuest={data}
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                />
              )}
            </>
          )}
        </div>
      </Layout>
    );
  }

  return (
    <div className="flex items-center justify-center flex-1 my-56">
      <Spinner color="livly" size="xl" />
    </div>
  );
}
