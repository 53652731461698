import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import { BASE_API_URL } from "../utils/constants";
import toLocalTime from "../utils/toLocalTime";
import { BaseLivlyApiResponse } from "../types/Base";
import { Spinner } from "../components/Spinner";
import useLivlyUser from "../context/UserProvider";

export type Event = {
  eventId: string;
  propertyId: number;
  propertyName: string;
  timezone: string;
  title: string;
  location: string;
  imageUrl: string;
  startDate: string | Date;
  endDate: string | Date;
  attending: boolean;
  isCancelled: boolean;
  rsvpDeadline: string | Date | null;
};

const getEvents = async (propertyId: number) => {
  const { data } = await axios.get<BaseLivlyApiResponse<Event[]>>(
    `${BASE_API_URL}/resident/events/property/${propertyId}/me/upcoming?includeCancelled=false`
  );

  return data.Data;
};

export const eventsQuery = (propertyId: number, leaseId: number) => ({
  queryKey: ["events", propertyId, leaseId],
  queryFn: async () => getEvents(propertyId)
});

export default function EventsPage() {
  const { user } = useLivlyUser();

  const { data, isLoading } = useQuery({
    ...eventsQuery(user.propertyId, user.propertyUnitLeaseId)
  });

  return (
    <Layout title="Events">
      {isLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <>
          <h2 className="mb-4">
            Browse available events and submit a booking request.
          </h2>
          <div className="grid lg:grid-cols-3 lg:gap-4 auto-rows-max">
            {data?.map((livlyEvent) => {
              return (
                <div
                  key={livlyEvent.eventId}
                  className="h-auto"
                  data-test-id="events-list-item"
                >
                  <Link
                    data-test-id="events-list-item-link"
                    to={`${livlyEvent.eventId}`}
                    className="flex flex-row mb-4 shadow-none text-inherit lg:shadow-md lg:flex-col lg:rounded-xl lg:border-gray-400 lg:hover:bg-gray-50 h-full"
                  >
                    {livlyEvent.imageUrl ? (
                      <img
                        className="flex-shrink-0 object-contain w-24 lg:w-full lg:h-36 lg:object-cover lg:rounded-tl-xl lg:rounded-tr-xl"
                        src={livlyEvent.imageUrl}
                        alt={livlyEvent.title}
                      />
                    ) : (
                      <div className="flex items-center justify-center flex-shrink-0 w-24 bg-gray-400 lg:w-full lg:h-36 lg:rounded-tl-xl lg:rounded-tr-xl">
                        <FontAwesomeIcon icon={["far", "calendar"]} size="3x" />
                      </div>
                    )}
                    <div className="flex-1 p-4 ml-3 border-b border-gray-400 lg:border-none lg:ml-0">
                      <div className="flex justify-between">
                        <div>
                          <p>{livlyEvent.title}</p>
                          <p className="font-light text-gray-600">
                            at {livlyEvent.location}
                          </p>
                        </div>
                        {livlyEvent.attending && (
                          <FontAwesomeIcon
                            icon={["far", "check-circle"]}
                            className="text-green-400"
                          />
                        )}
                      </div>
                      <div className="flex items-center gap-2 mt-3">
                        <FontAwesomeIcon icon={["far", "calendar"]} />
                        <p className="text-sm text-gray-500">
                          {toLocalTime(
                            livlyEvent.startDate,
                            livlyEvent?.timezone
                          ).format("ddd	MMM D")}
                        </p>
                      </div>
                      <div className="flex items-center gap-2 mt-2">
                        <FontAwesomeIcon icon={["far", "clock"]} />
                        <p className="text-sm text-gray-500">
                          {toLocalTime(
                            livlyEvent.startDate,
                            livlyEvent?.timezone
                          ).format("h:mm A")}
                        </p>
                      </div>
                      {livlyEvent?.rsvpDeadline && (
                        <div className="mt-2">
                          <p className="text-sm text-gray-400">
                            RSVP before{" "}
                            {toLocalTime(
                              livlyEvent.rsvpDeadline,
                              livlyEvent?.timezone
                            ).format("MMM D, h:mm A")}
                          </p>
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </>
      )}
    </Layout>
  );
}
