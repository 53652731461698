import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { BaseLivlyApiResponse } from "../types/Base";
import { Renewer, Task, TaskAlertType, User } from "../types/User";
import { BASE_API_URL } from "../utils/constants";
import { Button } from "./Button";

function TasksContainer({ user, tasks }: { user: User; tasks: Task[] }) {
  const renewalTask = tasks?.find(
    (task) => task.alertType === TaskAlertType.Renewal
  );

  return (
    <AnimatePresence>
      {renewalTask && <RenewalNotice user={user} />}
    </AnimatePresence>
  );
}

const getRenewer = async (leaseId: number) => {
  const result = await axios.get<BaseLivlyApiResponse<Renewer>>(
    `${BASE_API_URL}/livly/renewal/lease/${leaseId}`
  );

  return result.data.Data;
};

function RenewalNotice({ user }: { user: User }) {
  const { data, isLoading } = useQuery(
    ["user-renewal", user.propertyUnitLeaseId],
    () => getRenewer(user.propertyUnitLeaseId)
  );

  if (!isLoading && data) {
    const surveyDate = moment(user.leaseEndDate).format("MMMM D, YYYY");

    return (
      <motion.div
        className="pb-4 mx-4 mb-4 border-b border-b-200"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
      >
        <p className="text-sm">
          Your lease is up for renewal beginning <strong>{surveyDate}</strong>.
          Do you plan to renew your lease?
        </p>
        <Link
          to={`/lease/${user.propertyUnitLeaseId}/renewer`}
          className="block w-full mt-4"
        >
          <Button color="secondary" className="w-full">
            Tell your property
          </Button>
        </Link>
      </motion.div>
    );
  }

  return null;
}

export { TasksContainer as Tasks };

async function getTasks(leaseId: number) {
  const result = await axios.get<BaseLivlyApiResponse<Task[]>>(
    `${BASE_API_URL}/livly/users/me/tasks?leaseId=${leaseId}&v=202325`
  );

  return result.data.Data;
}

export function useLeaseTasks(leaseId: number) {
  return useQuery({
    queryKey: ["lease", "tasks", leaseId],
    queryFn: () => getTasks(leaseId),
    refetchOnWindowFocus: false,
  });
}
