import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Listbox, Transition } from "@headlessui/react";
import axios from "axios";
import { Fragment, ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import {
  PostTicketPhotoRequest,
  Ticket,
  TicketCategory,
  TicketPhoto
} from "../types/Maintenance";
import { BASE_API_URL } from "../utils/constants";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { Button } from "./Button";
import { FormField, TextArea, Toggle } from "./Form";
import { Trans, useTranslation } from "react-i18next";
import ImagesLightbox from "./ImagesLightbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Form, Formik } from "formik";
import { BaseLivlyApiResponse } from "../types/Base";
import { PhotoUpload } from "./PhotoUpload";
import {
  trackAddMaintenanceTicket,
  trackAddMaintenanceTicketAttachment
} from "../utils/analytics";
import { Spinner } from "./Spinner";
import toast from "react-hot-toast";
import { resizeImageAsBase64 } from "@/utils/resizeImage";
import useIsAppFolioMaintenanceProperty from "@/hooks/useIsAppFolioMaintenanceProperty";

export interface TicketPhotoCreateRequest {
  maintenanceTicketPhotoId: number;
  order?: number;
}

const TicketSchema = z.object({
  maintenanceCategoryId: z.string().optional(),
  hasPermissionToEnter: z.boolean().default(false),
  description: z.string(),
  accessInstructions: z.string().default("").optional(),
  photos: z.array(
    z.object({
      maintenanceTicketPhotoId: z.number(),
      order: z.number().optional()
    })
  ),
  leaseId: z.number(),
  existingChannelId: z.string().nullable()
});

type AddTicketRequest = z.infer<typeof TicketSchema>;

const getValidationSchema = (isAccessInstructionRequired: boolean) => {
  return TicketSchema.extend({
    accessInstructions: isAccessInstructionRequired
      ? z.string().min(1, "Access Instructions are required")
      : z.string().optional()
  });
};

function usePostTicket() {
  const postTicket = async (ticket: AddTicketRequest) => {
    return await axios.post(
      `${BASE_API_URL}/livly/maintenanceTicketsResidential`,
      ticket
    );
  };

  return useMutation(postTicket);
}

export default function AddTicketForm({
  setIsFieldDirty,
  defaultValues,
  isAccessInstructionRequired = false
}: {
  defaultValues?: Partial<AddTicketRequest>;
  setIsFieldDirty?: (value: boolean) => void;
  isAccessInstructionRequired?: boolean;
}) {
  const hideCategory = useIsAppFolioMaintenanceProperty();

  const params = useParams<{ leaseId: string; propertyId: string }>();
  const location = useLocation() as { state?: { existingChannelId: string } };
  const { existingChannelId = null } = location.state ?? {};
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [maintenanceCategoryId, setMaintenanceCategoryId] = useState("");
  const [hasPermissionToEnter, setHasPermissionToEnter] = useState(
    defaultValues?.hasPermissionToEnter ?? false
  );
  const [photos, setPhotos] = useState<TicketPhoto[]>([]);

  const { mutate, isLoading } = usePostTicket();

  const onSubmit = (ticket: AddTicketRequest) => {
    mutate(
      {
        ...ticket,
        maintenanceCategoryId,
        hasPermissionToEnter,
        photos,
        existingChannelId
      },
      {
        onSuccess: () => {
          trackAddMaintenanceTicket(hasPermissionToEnter, photos.length > 0);
          queryClient.invalidateQueries(["maintenance"]);
          navigate(`/lease/${params.leaseId}/maintenance/${params.propertyId}`);
        },
        onError: (e) => {
          const error = e as { data?: { Message?: string } };
          toast.error(error?.data?.Message ?? "An error occurred");
        }
      }
    );
  };

  const initialValues: AddTicketRequest = {
    maintenanceCategoryId: "",
    hasPermissionToEnter: false,
    description: "",
    accessInstructions: "",
    photos: [],
    leaseId: Number(params.leaseId),
    existingChannelId: null,
    ...defaultValues
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={toFormikValidationSchema(
        getValidationSchema(isAccessInstructionRequired)
      )}
      onSubmit={onSubmit}
    >
      {({ errors, touched, values, handleChange }) => (
        <Form>
          {/* {error?.data?.Errors && (
        <div className="p-4 my-2 bg-red-100 border-l-4 border-red-500 rounded-md">
          {JSON.stringify(error?.data?.Errors, null, 2)}
        </div>
      )} */}
          {/* check erp is not appfolio */}
          {!hideCategory && (
            <label className="block text-sm font-medium text-gray-700">
              Category
            </label>
          )}

          <CategorySelect
            value={""}
            leaseId={params.leaseId!}
            onChange={setMaintenanceCategoryId}
            setIsFieldDirty={setIsFieldDirty}
            hideCategory={hideCategory}
          />
          <FormField
            htmlFor="description"
            label="Description"
            showErrorIcon={errors.description && touched.description}
          >
            <p className="mt-2 text-sm text-gray-500">
              Please enter a short description of the issue.
            </p>
            <TextArea
              id="description"
              name="description"
              className="mt-2"
              onChange={(e) => {
                handleChange(e);
                if (setIsFieldDirty) {
                  setIsFieldDirty(true);
                }
              }}
            />
          </FormField>

          <FormField
            htmlFor="accessInstructions"
            label="Access Instructions"
            showErrorIcon={
              errors.accessInstructions && touched.accessInstructions
            }
          >
            <p className="mt-2 text-sm text-gray-500">
              Enter any details or instruction on accessing the unit.
            </p>
            <TextArea
              id="accessInstructions"
              name="accessInstructions"
              className="mt-2"
              onChange={(e) => {
                handleChange(e);
                if (setIsFieldDirty) {
                  setIsFieldDirty(true);
                }
              }}
            />
          </FormField>

          <FormField
            htmlFor="hasPermissionToEnter"
            label="Permission to enter"
            className="flex items-center justify-between gap-4 md:justify-start"
          >
            <Toggle
              checked={hasPermissionToEnter}
              onChange={(e) => {
                setHasPermissionToEnter(e);
                if (setIsFieldDirty) {
                  setIsFieldDirty(true);
                }
              }}
            />
          </FormField>
          <p className="mt-2 text-sm text-gray-500">
            By granting permission to enter, the resident has authorized the
            landlord, maintenance engineer or Livly to enter the unit at the
            time of this request.
          </p>
          <TicketPhotos
            photos={photos}
            setTicketPhotos={setPhotos}
            setIsFieldDirty={setIsFieldDirty}
          />
          <div className="flex justify-end mt-4">
            <Button
              size="small"
              color="primary"
              type="submit"
              className="w-full md:w-auto"
              disabled={
                isLoading ||
                maintenanceCategoryId === "" ||
                !values.description ||
                (isAccessInstructionRequired && !values.accessInstructions)
              }
            >
              {isLoading && <Spinner />}
              Create
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

const getTicketCategories = async (leaseId: string, parentId?: String) => {
  const result = await axios.get<BaseLivlyApiResponse<TicketCategory[]>>(
    `${BASE_API_URL}/livly/maintenanceTicketsResidential/categories`,
    {
      params: {
        leaseId,
        parentId
      }
    }
  );

  return result.data.Data;
};

const ticketsCategoriesQuery = (leaseId: string, parentId: string) => ({
  queryKey: ["maintenance", "categories", leaseId, parentId],
  queryFn: async () => getTicketCategories(leaseId, parentId)
});

function CategorySelect({
  value,
  leaseId,
  onChange,
  setIsFieldDirty,
  hideCategory
}: {
  value: string;
  onChange: (categoryId: string) => void;
  leaseId: string;
  setIsFieldDirty?: (value: boolean) => void;
  hideCategory?: boolean;
}) {
  const [innerValue, setInnerValue] = useState(value);
  const {
    data = [],
    isLoading,
    isError
  } = useQuery(ticketsCategoriesQuery(leaseId, value));

  const selectedCategory = data.find(
    (tc) => tc.maintenanceCategoryId === innerValue
  );

  // if appfolio set first category
  useEffect(() => {
    if (hideCategory && data.length > 0) {
      onChange(data[0].maintenanceCategoryId);
    }
  }, [hideCategory, data]);

  if (hideCategory) {
    return null;
  }

  return (
    <>
      <div>
        {isError ? (
          <p>Error loading category</p>
        ) : isLoading ? (
          <Spinner color="livly" />
        ) : (
          <>
            <Listbox
              value={innerValue}
              onChange={(value) => {
                if (value === "") {
                  return;
                }
                const selectedCategory = data.find(
                  (tc) => tc.maintenanceCategoryId === value
                );

                if (!selectedCategory?.hasChildren && value !== "") {
                  // set master category value
                  onChange(value);
                } else {
                  onChange("");
                }
                setInnerValue(value);
                if (setIsFieldDirty) {
                  setIsFieldDirty(true);
                }
              }}
            >
              {({ open }) => (
                <>
                  <div className="relative mt-2">
                    <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                      <span className="block truncate">
                        {selectedCategory?.name ?? "Select Category"}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <FontAwesomeIcon icon="chevron-down" />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {data.map((cat) => (
                          <Listbox.Option
                            key={cat.maintenanceCategoryId}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-blue-600"
                                  : "text-gray-900",
                                "relative cursor-default select-none py-2 pl-3 pr-9"
                              )
                            }
                            value={cat.maintenanceCategoryId}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? "font-semibold" : "font-normal",
                                    "block truncate"
                                  )}
                                >
                                  {cat.name}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? "text-white" : "text-blue-600",
                                      "absolute inset-y-0 right-0 flex items-center pr-4"
                                    )}
                                  >
                                    <FontAwesomeIcon
                                      icon={["far", "check-circle"]}
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>

            {selectedCategory?.hasChildren && (
              <CategorySelect
                value={innerValue}
                leaseId={leaseId}
                onChange={onChange}
                setIsFieldDirty={setIsFieldDirty}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

function usePostTicketPhoto() {
  const postTicketPhoto = async (ticketPhoto: PostTicketPhotoRequest) => {
    const result = await axios.post<BaseLivlyApiResponse<TicketPhoto>>(
      `${BASE_API_URL}/livly/maintenanceTicketsResidential/photos`,
      ticketPhoto
    );

    return result.data.Data;
  };

  return useMutation(postTicketPhoto);
}

export function TicketPhotos({
  photos,
  setTicketPhotos,
  hideList = false,
  maintenanceTicketId,
  isDisabled,
  setIsFieldDirty
}: {
  maintenanceTicketId?: number;
  hideList?: boolean;
  photos: TicketPhoto[];
  setTicketPhotos: (photos: TicketPhoto[]) => void;
  isDisabled?: boolean;
  setIsFieldDirty?: (value: boolean) => void;
}) {
  const [isUploadingPhotos, setIsUploadingPhotos] = useState(false);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const onImageClick = (index: number) => {
    setSelectedImageIndex(index);
    setIsLightboxOpen(true);
  };

  const { t } = useTranslation();
  const { mutateAsync: uploadTicketPhoto } = usePostTicketPhoto();

  const onUploadPhotos = (
    decodedPhotos: File[],
    skippedPhotosCount: number
  ) => {
    const doUploadTicketPhotos = async () => {
      const newPhotos: TicketPhoto[] = [];

      for (const decodedPhoto of decodedPhotos) {
        try {
          const base64Image = await resizeImageAsBase64(decodedPhoto);
          const photo = await uploadTicketPhoto({
            base64Image,
            maintenanceTicketId
          });

          newPhotos.push(photo);
        } catch (e: any) {
          // Just continue photos uploading
        }
      }

      if (newPhotos.length < decodedPhotos.length) {
        alert(t("tickets.photo-upload.network-error"));
      } else if (skippedPhotosCount) {
        alert(
          t("tickets.photo-upload.warning", {
            count: skippedPhotosCount
          })
        );
      } else {
        if (setIsFieldDirty) {
          setIsFieldDirty(true);
        }
      }

      trackAddMaintenanceTicketAttachment();
      setTicketPhotos([...photos, ...newPhotos]);
      setIsUploadingPhotos(false);
    };

    doUploadTicketPhotos();
  };

  return (
    <div className="mt-6">
      {!hideList && (
        <>
          {" "}
          <label className="block text-sm font-medium text-gray-700">
            Photos
          </label>
          {photos && photos.length > 0 && (
            <div className="mt-2">
              {photos.map((photo, index) => (
                <div
                  key={photo.maintenanceTicketPhotoId}
                  className="flex justify-between"
                >
                  <div className="flex items-center justify-between w-full mb-4">
                    <div className="flex items-center">
                      <img
                        className="object-cover w-24 h-16"
                        src={photo.thumbnailUri}
                        alt="Issue photo"
                        onClick={() => onImageClick(index)}
                      />
                      <div className="flex flex-col ml-4">
                        <h4>
                          {t("tickets.create.photo-name", { index: index + 1 })}
                        </h4>
                        <p
                          className="text-sm underline cursor-pointer"
                          onClick={() => {
                            const newPhotos = photos.filter(
                              (ph) =>
                                ph.maintenanceTicketPhotoId !==
                                photo.maintenanceTicketPhotoId
                            );
                            setTicketPhotos(newPhotos);
                          }}
                        >
                          {t("tickets.create.photo-remove")}
                        </p>
                      </div>
                    </div>
                    <div className="ml-4">
                      <FontAwesomeIcon
                        icon={["far", "check-circle"]}
                        className="text-green-500"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}

      {isUploadingPhotos && (
        <div className="flex items-center justify-center gap-2 py-2">
          <Spinner color="livly" />
          <p>{t("tickets.uploading-photos")}</p>
        </div>
      )}

      {isLightboxOpen && (
        <ImagesLightbox
          images={photos.map((photo) => photo.thumbnailUri)}
          onClose={() => setIsLightboxOpen(false)}
          selectedImageIndex={selectedImageIndex}
        />
      )}

      {isDisabled ? null : (
        <PhotoUpload
          onFilesUploadStart={() => setIsUploadingPhotos(true)}
          onFilesUpload={onUploadPhotos}
          onFilesUploadError={(error) => setIsUploadingPhotos(false)}
        >
          {(props) => (
            <div className="w-full h-24 mt-2 border border-gray-200 rounded-lg">
              <div className="relative flex flex-col items-center justify-center h-full">
                <h4 className="text-gray-600">
                  {t("tickets.photo-upload.drag-photos")}
                </h4>
                <div className="mt-2">
                  <Trans i18nKey="tickets.photo-upload.browse-photos">
                    or{" "}
                    <span
                      className="text-red-400 underline cursor-pointer"
                      onClick={props.onClick}
                    >
                      browse
                    </span>
                  </Trans>
                </div>
              </div>
            </div>
          )}
        </PhotoUpload>
      )}
    </div>
  );
}
