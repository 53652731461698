import moment, { Moment } from "moment-timezone";

const convertUTCToLocalTime = (
  date: Date | string,
  timezone?: string | null
): Moment => {
  if (timezone) {
    return moment.utc(date).tz(timezone);
  }
  return moment.utc(date).local();
};

export function humanizeTimestamp(timestamp: string | number): string {
  const time = moment.utc(timestamp);
  const now = moment();
  return moment.min(time, now).from(now, true);
}

export const isRSVPDeadlinePassed = (
  rsvpDeadline: Date | string,
  timezone?: string | null
): boolean => {
  const deadline = convertUTCToLocalTime(rsvpDeadline, timezone);
  const now = moment().tz(timezone || moment.tz.guess());
  return now.isAfter(deadline);
};

export default convertUTCToLocalTime;
