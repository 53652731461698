import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faTimes,
  faBuilding,
  faEllipsisH,
  faGlassCheers as faGlassCheersSolid,
  faDumbbell as faDumbbellFreeSolid,
  faTools as faToolsSolid,
  faCalendarPlus as faCalendarPlusSolid,
  faTag as faTagSolid,
  faSackDollar as faSackDollarSolid,
  faAddressBook as faAddressBookSolid,
  faPoll as faPollSolid,
  faUmbrellaBeach as faUmbrellaBeachSolid,
  faChevronRight,
  faCar,
  faDog,
  faArrowLeft,
  faExclamationTriangle,
  faUser,
  faDollarSign,
  faClock,
  faMapMarkerAlt,
  faFilter,
  faChevronLeft,
  faSignOut,
  faCheck,
  faArrowRight,
  faShieldHalved,
  faList,
  faBurn,
  faPlug,
  faFaucetDrip,
  faWifi,
  faPhone,
  faLink,
  faCloudUpload,
  faFileAlt,
  faFileImage,
  faFilePdf,
  faFileInvoiceDollar,
  faEye,
  faEyeSlash,
  faImagePortrait,
  faImage,
  faPaw,
  faCamera,
  faCircle,
  faRotateRight,
  faSackDollar,
  faTimesCircle,
  faCopy,
  faBell,
  faExclamationCircle,
  faHandHoldingDollar,
  faComment as faCommentSolid,
  faInfoCircle as faInfoCircleSolid,
  faKey
} from "@fortawesome/free-solid-svg-icons";
import {
  faGlassCheers,
  faDumbbell,
  faTools,
  faCalendarPlus,
  faTag,
  faAddressBook,
  faCommentAltCheck,
  faPoll,
  faUmbrellaBeach,
  faHomeAlt,
  faCalendar as faCalendarLight,
  faComment,
  faUser as faUserLight,
  faCircleCheck,
  faShield,
  faUp,
  faInfoCircle,
  faUpToLine,
  faEnvelope,
  faMailbox,
  faPlusCircle,
  faSliders,
  faChevronDown as faChevronDownLight
} from "@fortawesome/pro-light-svg-icons";
import {
  faHomeAlt as faHomeAltSolid,
  faCommentAltCheck as faCommentAltCheckSolid,
  faLoader,
  faListCheck,
  faWallet,
  faBadgeCheck,
  faPlus,
  faMinus,
  faChevronDown,
  faTrashCan as faTrashCanSolid,
  faBed,
  faCouch,
  faDoorOpen,
  faOven,
  faQuestionCircle,
  faToilet,
  faPenToSquare,
  faEmptySet,
  faCog,
  faBan,
  faExclamationSquare as faExclamationSquareSolid,
  faSwimmingPool,
  faPartyHorn,
  faBiking,
  faBullhorn,
  faBus,
  faConciergeBell,
  faDog as faDogSolid,
  faDumbbell as faDumbbellSolid,
  faFileAlt as faFileAltSolid,
  faGamepad,
  faMartiniGlassCitrus,
  faUtensils,
  faBandage,
  faPallet,
  faShoppingBasket,
  faCake,
  faCalendarExclamation,
  faBellConcierge,
  faFilm,
  faFirstAid,
  faHeart,
  faMusic,
  faVacuum,
  faBasketball,
  faHourglassClock,
  faUsers as solidUsers
} from "@fortawesome/pro-solid-svg-icons";
import {
  faBuilding as faBuildingRegular,
  faClock as faClockRegular,
  faCalendar,
  faCheckCircle,
  faTrashCan,
  faExclamationSquare,
  faCreditCard,
  faUniversity,
  faLightbulbOn,
  faRightLeft,
  faCircleExclamation,
  faUsers,
  faTvRetro,
  faCalendarDay,
  faArrowUpToLine,
  faHouseWater,
  faMessageDots,
  faImageUser,
  faSearch,
  faUmbrella,
  faTruckRampCouch,
  faCartShopping,
  faUser as faUserRegular,
  faForward
} from "@fortawesome/pro-regular-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTiktok
} from "@fortawesome/free-brands-svg-icons";
import {
  faMobile,
  faAngry,
  faFrown,
  faSmile,
  faGrinHearts,
  faSackDollar as faSackDollarDuo,
  faWrench
} from "@fortawesome/pro-duotone-svg-icons";

library.add(
  faCake,
  faCalendarExclamation,
  faBellConcierge,
  faFilm,
  faFirstAid,
  faHeart,
  faMusic,
  faVacuum,
  faShoppingBasket,
  faBandage,
  faPallet,
  faUtensils,
  faMartiniGlassCitrus,
  faBasketball,
  faGamepad,
  faDumbbellFreeSolid,
  faSwimmingPool,
  faPartyHorn,
  faBiking,
  faBullhorn,
  faBus,
  faConciergeBell,
  faDogSolid,
  faDumbbellSolid,
  faFileAltSolid,
  faExclamationSquareSolid,
  faKey,
  faWrench,
  faBan,
  faCog,
  faCar,
  faDog,
  faChevronRight,
  faDumbbellSolid,
  faToolsSolid,
  faCalendarPlusSolid,
  faBars,
  faTagSolid,
  faTimes,
  faSackDollarSolid,
  faPollSolid,
  faUmbrellaBeachSolid,
  faGlassCheers,
  faDumbbell,
  faTools,
  faCalendarPlus,
  faTag,
  faSackDollar,
  faAddressBook,
  faCommentAltCheck,
  faPoll,
  faUmbrellaBeach,
  faHomeAlt,
  faEllipsisH,
  faBuilding,
  faHomeAltSolid,
  faAddressBookSolid,
  faGlassCheersSolid,
  faArrowLeft,
  faExclamationTriangle,
  faUser,
  faDollarSign,
  faClock,
  faMapMarkerAlt,
  faFilter,
  faBuildingRegular,
  faClockRegular,
  faCalendar,
  faCalendarLight,
  faChevronLeft,
  faInstagram,
  faTiktok,
  faCheckCircle,
  faCommentAltCheckSolid,
  faSignOut,
  faLoader,
  faMobile,
  faListCheck,
  faWallet,
  faBadgeCheck,
  faComment,
  faMinus,
  faPlus,
  faChevronDown,
  faAngry,
  faFrown,
  faSmile,
  faGrinHearts,
  faCheck,
  faTrashCan,
  faExclamationSquare,
  faCreditCard,
  faUniversity,
  faArrowRight,
  faUserLight,
  faShieldHalved,
  faList,
  faCartShopping,
  faLightbulbOn,
  faRightLeft,
  faCircleExclamation,
  faUsers,
  faBurn,
  faPlug,
  faFaucetDrip,
  faWifi,
  faTvRetro,
  faPhone,
  faLink,
  faCloudUpload,
  faFileAlt,
  faFileImage,
  faFilePdf,
  faForward,
  faFileInvoiceDollar,
  faEye,
  faEyeSlash,
  faTrashCanSolid,
  faCircleCheck,
  faShield,
  faUp,
  faImagePortrait,
  faImage,
  faPaw,
  faCamera,
  faSackDollar,
  faSackDollarDuo,
  faCircle,
  faRotateRight,
  faInfoCircle,
  faTimesCircle,
  faCalendarDay,
  faArrowUpToLine,
  faToilet,
  faBed,
  faDoorOpen,
  faOven,
  faCouch,
  faQuestionCircle,
  faPenToSquare,
  faHouseWater,
  faCopy,
  faMobile,
  faBell,
  faFacebook,
  faInstagram,
  faLinkedin,
  faMessageDots,
  faImageUser,
  faExclamationCircle,
  faMailbox,
  faEnvelope,
  faHandHoldingDollar,
  faEmptySet,
  faCalendar,
  faPlusCircle,
  faSearch,
  faSliders,
  faChevronDownLight,
  faUmbrella,
  faTruckRampCouch,
  faUserRegular,
  faCommentSolid,
  faInfoCircleSolid,
  faHourglassClock,
  solidUsers
);
