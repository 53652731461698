import { Attendee } from "@/routes/event";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";

interface EventContextShape {
  attendee: Partial<Attendee>;
  updateAttendee: (user: Partial<Attendee>) => void;
  resetAttendee: () => void;
}

const initialState: EventContextShape = {
  attendee: {} as Partial<Attendee>,
  updateAttendee: () => {},
  resetAttendee: () => {}
};

const EventContext = createContext<EventContextShape>(
  initialState as EventContextShape
);

const initialAttendee: Partial<Attendee> = {
  attendingCount: 1,
  rsvpNote: "",
  guests: [],
  isRsvpNoteRequired: false,
  allowedGuestCount: 0,
  remainingSpots: null,
  isGuestsInfoRequired: false,
  rsvpFields: []
};

// Create a provider component that will wrap your application
function AttendeeRequestProvider() {
  const [attendee, setAttendeeRequest] =
    useState<Partial<Attendee>>(initialAttendee);

  const updateAttendee = useCallback((newData: Partial<Attendee>) => {
    setAttendeeRequest((prevData) => ({ ...prevData, ...newData }));
  }, []);

  const resetAttendee = useCallback(() => {
    setAttendeeRequest(initialAttendee);
  }, []);

  // Provide the context value to the components in the tree

  const value = useMemo(
    () => ({
      attendee: attendee!,
      updateAttendee,
      resetAttendee
    }),
    [attendee, updateAttendee, resetAttendee]
  );

  return (
    <EventContext.Provider value={value}>
      <ScrollRestoration />
      <Outlet />
    </EventContext.Provider>
  );
}

export { AttendeeRequestProvider };

export default function useLivlyAttendee() {
  const context = useContext(EventContext);
  if (!context) {
    throw new Error(`useAttendeeUser must be used within a UserProvider`);
  }

  return context;
}
