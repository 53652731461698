import { Button } from "@/components/Button";
import { FormField } from "@/components/Form";
import Layout from "@/components/Layout";
import useLivlyGuest, {
  DelegateServiceTypes,
  GuestSchema,
  phonePlaceholder
} from "@/context/GuestProvider";

import { Field, Form, Formik, useField } from "formik";
import React, { useState } from "react";
import InputMask from "react-input-mask";
import { useNavigate, useParams } from "react-router-dom";
import { toFormikValidationSchema } from "zod-formik-adapter";
import flag from "../../assets/us_flag.svg";
import useGetService from "@/hooks/useGetService";
import { ServiceTypeEnum } from "@/types/Building";
import { useQueryClient } from "@tanstack/react-query";
import useLivlyUser from "@/context/UserProvider";
import LivlySwitch from "@/components/LivlySwitch";

export function useGetLivlyLockService(propertyId: number, leaseId: string) {
  const queryClient = useQueryClient();
  const services: DelegateServiceTypes[] =
    queryClient.getQueryData(["delegate-services", propertyId, leaseId]) ?? [];
  const LivlyLocks = services.find(
    (p) => p.serviceType === ServiceTypeEnum.LivlyLocks
  );

  return LivlyLocks?.enabled ?? false;
}

const Permission = () => {
  const { leaseId } = useParams<{ id: string; leaseId: string }>();

  const { guest, updateGuest } = useLivlyGuest();
  const { user } = useLivlyUser();
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(guest?.appPermission ?? false);
  const livlyLockEnabled =
    useGetLivlyLockService(user.propertyId, leaseId!) &&
    user?.isLivlyKeyEnabled;
  const service = useGetService(ServiceTypeEnum.LivlyLocks);
  const onSubmitRefine = () => {
    updateGuest(guest);
  };

  return (
    <Layout
      back={{
        to: "..",
        label: guest?.guestId ? "Edit Guest" : "Add Guest",
        state: true
      }}
      title="Access permission"
      button={
        !enabled ? (
          <Button
            color="default"
            onClick={() => {
              updateGuest({ ...guest, appPermission: false });
              navigate("../review", { state: { skipped: true } });
            }}
            className="flex items-center w-full gap-2 md:w-auto"
          >
            Skip
          </Button>
        ) : (
          <></>
        )
      }
    >
      <div className="max-w-3xl mx-auto">
        <Formik
          initialValues={guest}
          validationSchema={toFormikValidationSchema(GuestSchema)}
          onSubmit={onSubmitRefine}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form>
              <div className="flex justify-between items-center">
                <p className="text-2xl font-medium">App permission</p>
              </div>
              <div className="mt-4">
                <p className="break-words text-base text-gray-600">
                  Allow guests to have access to the app. You can choose the
                  features you would like this guest to be able to access on
                  your behalf.
                </p>
              </div>
              <div className="flex justify-end gap-2 mt-1">
                <label>
                  <LivlySwitch
                    checked={enabled ?? false}
                    onChange={(checked: boolean) => {
                      setEnabled(checked);
                      setFieldValue("appPermission", checked);
                    }}
                  />
                </label>
              </div>

              {enabled && (
                <div>
                  <div className="mt-4">
                    <p className="break-words text-base text-gray-500">
                      This user’s phone number and email address are required to
                      access the app.
                    </p>
                  </div>
                  <FormField
                    htmlFor="phone"
                    label="Phone"
                    showErrorIcon={errors.phone && touched.phone}
                    isRequired
                  >
                    <div className="flex items-center">
                      <div>
                        <img src={flag} alt="US Flag" />
                      </div>
                      <p className="mx-2 text-sm">+1</p>
                      <MyPhoneInput name="phone" id="phone" />
                    </div>
                  </FormField>

                  <FormField
                    htmlFor="email"
                    label="Email"
                    showErrorIcon={errors.email && touched.email}
                    isRequired
                  >
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      disabled={guest?.hadAppPermissionGranted}
                      className={`block w-full border-0 border-b border-transparent bg-gray-50 focus:border-blue-600 focus:ring-0 sm:text-sm ${
                        guest?.hadAppPermissionGranted && "cursor-not-allowed"
                      }`}
                    />
                    <p className="text-xs font-light text-gray-500 mt-2">
                      An invitation to download will be sent to this email
                      address.
                    </p>
                  </FormField>
                </div>
              )}
              <div className="flex justify-end gap-2 mt-8">
                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    updateGuest(values);
                    service?.enabled && livlyLockEnabled
                      ? navigate("../livly-key-permission")
                      : navigate("../livly-feature-permission", {
                          state: { skipped: true }
                        });
                  }}
                  disabled={
                    !enabled ||
                    !values.email ||
                    !values.phone ||
                    !!errors?.email ||
                    !!errors?.phone ||
                    values.phone == phonePlaceholder
                  }
                  className="flex items-center gap-2"
                >
                  Next
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};

function MyPhoneInput(props: JSX.IntrinsicElements["input"]) {
  const { guest } = useLivlyGuest();
  const [field] = useField(props.name!);
  return (
    <InputMask
      mask="(999) 999-9999"
      disabled={guest?.hadAppPermissionGranted}
      {...field}
    >
      {
        //@ts-ignore
        (inputProps: any) => {
          return (
            <input
              {...inputProps}
              {...props}
              type="tel"
              placeholder="Phone number"
              className={`block w-full border-0 border-b border-transparent bg-gray-50 focus:border-blue-600 focus:ring-0 sm:text-sm ${
                guest?.hadAppPermissionGranted && "cursor-not-allowed"
              }`}
            />
          );
        }
      }
    </InputMask>
  );
}

export default Permission;
